<template>
  <div class="p-pegas">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
          <div class="card-table-body ifon-add-title-block">
              <el-row :gutter="20">
                  <el-col :span="20">
                      <div class="caz-blocks-sarcho-title">
                          <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                              {{ $t("message.yearly") }} {{ vacation_plane.year }}
                          </div>
                          <div class="block-sarche">
                              <div class="header__search">
                                  <crm-input
                                      :size="'mini'"
                                      :className="'w100'"
                                      :class="mode ? 'input__day' : 'input__night'"
                                      v-model="filterForm.search"
                                      :icon="'el-icon-search'"
                                  ></crm-input>
                              </div>
                          </div>
                          <div class="ml-2 block-sarche">
                            <select-branch
                                :size="'mini'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.branch_id"
                              >
                            </select-branch>
                          </div>
                          <div class="ml-2 block-sarche">
                            <select-department
                              :size="'mini'"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.department_id"
                            >
                          </select-department>
                          </div>
                          <div class="ml-2 block-sarche">
                            <select-position
                                :size="'mini'"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.position_id"
                              >
                            </select-position>
                          </div>
                      </div>
                  </el-col>

                  <el-col :span="4" class="flex-style text-right">
                    <router-link  :to="{ name: 'vacationPlane'}" >
                      <el-button type="primary" size="small" class="mr-2">{{$t('message.back')}}</el-button>
                     </router-link>
                    <el-button type="success" :size="'small'" @click="save()"
                    > {{$t('message.save')}}</el-button>
                      <!-- <crm-create-and-column-settings
                          :permission="$options.name"
                          @c-create="drawerCreate = true"
                          :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                          :columns="columns"
                          @c-change="updateColumn"
                          :v_can_create="'VacationManagementController@store'"
                      >
                      </crm-create-and-column-settings> -->
                  </el-col>
              </el-row>
          </div>

          <el-input
              type="hidden"
              size="mini"
              v-model="filterForm.vacation_plane_id"
              :value="$route.params.id"
          ></el-input>
          <!-- end ifon-add-title-block -->
          <div class="card-table-header table-crm-smart">
              <table
                  class="table-my-code table-bordered"
                  :class="mode ? 'table__myday' : 'table__mynight'"
                  v-loading="loadingData"
              >
                  <thead>
                      <tr>
                          <th class="w50p" v-if="columns.id.show">
                              {{ columns.id.title }}
                          </th>
                          <th v-if="columns.staff.show">
                            {{ columns.staff.title }}
                          </th>
                          <th v-if="columns.month.show">
                            {{ columns.month.title }}
                          </th>
                          <th v-if="columns.day_count.show">
                            {{ columns.day_count.title }}
                          </th>
                         
                          <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                          </th>

                          <th v-if="columns.updated_at.show">
                              {{ columns.updated_at.title }}
                          </th>

                          <!-- <th v-if="columns.settings.show">
                              {{ columns.settings.title }}
                          </th> -->
                      </tr>
                      <tr class="filter_sorche">
                          <th v-if="columns.id.show">
                            <el-input
                                  clearable
                                  size="mini"
                                  v-model="filterForm.id"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  :placeholder="columns.id.title"
                                  class="id_input"
                              ></el-input>
                          </th>
                          <th v-if="columns.staff.show">
                            <el-input
                                  clearable
                                  size="mini"
                                  v-model="filterForm.staff"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  :placeholder="columns.staff.title"
                              ></el-input>
                          </th>
                          <th v-if="columns.month.show">
                            <el-date-picker
                                v-model="filterForm.month"
                                  type="month"
                                  :size="'mini'"
                                  :format="'MMMM'"
                                  :value-format="'MM'"
                                  :placeholder="columns.month.title"
                                style="width:100%">
                            </el-date-picker>
                          </th>
                          <th v-if="columns.day_count.show">
                            <el-input
                              clearable
                              size="mini"
                              :placeholder="columns.day_count.title"
                              v-model="filterForm.staff_count"
                              :class="mode ? 'filter__day' : 'filter__night'"
                          ></el-input>
                          </th>
                          <th v-if="columns.created_at.show">
                              <crm-date-picker
                                :placeholder="columns.created_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.created_at"
                              ></crm-date-picker>
                          </th>
                          <th v-if="columns.updated_at.show">
                              <crm-date-picker
                                  :placeholder="columns.updated_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.updated_at"
                              ></crm-date-picker>
                          </th>
                          <!-- <th
                              class="settinW"
                              v-if="columns.settings.show"
                          ></th> -->
                      </tr>
                  </thead>
                  <transition-group name="flip-list" tag="tbody">
                      <tr
                          v-for="vacation_plane_staff in list"
                          :key="vacation_plane_staff.id"
                          class="cursor-pointer"
                      >
                          <td v-if="columns.id.show">{{ vacation_plane_staff.id }}</td>
                          <td v-if="columns.staff.show">
                            <ul >
                              <li class="">
                                <b class="title_b">{{$t('message.staff')}}: </b>
                                <span class="text_b">{{vacation_plane_staff.staff.name}} {{vacation_plane_staff.staff.last_name}}</span>
                              </li>
                              
                              <li class="">
                                <b class="title_b">{{$t('message.branch')}}: </b>
                                <span>{{vacation_plane_staff.staff.branch?vacation_plane_staff.staff.branch.name:''}}</span>
                              </li>
                              <li class="">
                                <b class="title_b">{{$t('message.department')}}: </b>
                                <span>{{vacation_plane_staff.staff.department?vacation_plane_staff.staff.department.name:''}}</span>
                              </li>
                              <li class="">
                                <b class="title_b">{{$t('message.position')}}: </b>
                                <span>{{vacation_plane_staff.staff.position?vacation_plane_staff.staff.position.name:''}}</span>
                              </li>
                              <li class="">
                                <b class="title_b">{{$t('message.hiring_date')}}: </b>
                                <span>{{vacation_plane_staff.staff.hiring_date}}</span>
                              </li>
                              <li class="">
                                <b class="title_b">{{$t('message.official_date')}}: </b>
                                <span>{{vacation_plane_staff.staff.official_work_date}}</span>
                              </li>
                            </ul>
                            <!-- {{ vacation_plane_staff.staff.name }} -->
                          </td>
                          <td v-if="columns.month.show">
                            <el-date-picker
                              v-model="vacation_plane_staff.month"
                              type="month"
                              :size="'small'"
                              :format="'MMMM'"
                              :value-format="'MM'"
                              style="width:100%">
                          </el-date-picker>
                          <!-- {{ vacation_plane_staff.staff_count }} -->
                        </td>
                        <td v-if="columns.day_count.show">
                              <!-- <el-input
                              clearable
                              size="mini"
                              v-model="vacation_plane_staff.day_count"
                              :class="mode ? 'filter__day' : 'filter__night'"
                          ></el-input> -->
                          <el-input-number 
                           
                          :class="mode ? 'filter__day' : 'filter__night'" 
                          v-model="vacation_plane_staff.day_count" 
                          controls-position="right"
                          size="small"
                          class="w-100"
                          :min="0" :max="100"
                          ></el-input-number>
                        </td>
                          <td v-if="columns.created_at.show">
                              {{ vacation_plane_staff.created_at }}
                          </td>
                          <td v-if="columns.updated_at.show">
                              {{ vacation_plane_staff.updated_at }}
                          </td>
                          <!-- <td
                              v-if="columns.settings.show"
                              class="settings-td"
                          >
                          </td> -->
                      </tr>
                  </transition-group>
              </table>
              <div class="my___pagination">
                  <crm-pagination
                      @c-change="updatePagination"
                      :class="mode ? 'pagination__day' : 'pagination__night'"
                      :pagination="pagination"
                  ></crm-pagination>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import moment from 'moment';
import form from "@/utils/mixins/form";
import selectStaff from "@/components/filters/inventory/select-staff";

import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "RegionController",
  mixins: [list, form],
  components: {
      selectStaff
  },

  data() {
      return {
        staffs:null,
        form:{
          month:[

        ]},
      
      };
  },
  // created(){
  //   this.fetchData()
  // },
  computed: {
      ...mapGetters({
          list: "vacationPlaneStaff/list",
          vacation_plane: "vacationPlaneStaff/vacation_plane",
          columns: "vacationPlaneStaff/columns",
          pagination: "vacationPlaneStaff/pagination",
          statues: "vacationPlaneStaff/statues",
          filter: "vacationPlaneStaff/filter",
          sort: "vacationPlaneStaff/sort",
          mode: "MODE"
      }),
      actions: function () {
          return ["edit", "delete"];
      }
  },
  methods: {
      ...mapActions({
          updateList: "vacationPlaneStaff/index",
          updateStaffList: "vacationPlaneStaff/updateStaffList",
          setPagination: "vacationPlaneStaff/setPagination",
          updateSort: "vacationPlaneStaff/updateSort",
          updateFilter: "vacationPlaneStaff/updateFilter",
          updateColumn: "vacationPlaneStaff/updateColumn",
          updatePagination: "vacationPlaneStaff/updatePagination",
          empty: "vacationPlaneStaff/empty",
          delete: "vacationPlaneStaff/destroy",
          refreshData: "vacationPlaneStaff/refreshData",
      }),
      fetchData() { 
            const query = {
                ...this.filter,
                ...this.pagination,
                ...this.sort,
            };
            if (!this.loadingData) {
                this.loadingData = true;
                if (this.list) {
                  
                }
                this.updateList({route_id: this.$route.params.id, ...query}).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
      formatDate(value){
        if (value) {
          return moment((value)).lang("ru").format('MMMM YYYY')
        }
      },
      formatPeryotDate(value){
        if (value) {
          return moment((value)).lang("ru").format('DD.MM.YYYY')
        }
      },
      save(){
        this.loadingData =true;
        const newArray = this.list.map(function(x){
          return {
              'id':x.id,
              'day_count':x.day_count,
              'month':x.month,
          };
          });
          const query ={
            id:this.$route.params.id,
            data:newArray
          }
        this.updateStaffList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
      }
  },
};
</script>

<style lang="scss">
.title_b{
  color: #8f8f8f;
}
</style>

